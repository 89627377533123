import LayoutAdmin from '@/layouts/LayoutAdmin'

let urutantableSIMURP = 0
let urutantableIPDMIP = 0
let urutantableDOISP = 0

export default {
  data() {
    return {
      search: '',
      loadingTb: false,
      isActive: [],
      options: null,
      summary: [],
      tableSBSN: [],
      tableProyek: [],
      tableLoan: [],
      tableSatker: [],
      tableSIMURP: [],
      tableDOISP: [],
      tableIPDMIP: [],
      tableOutput:[],
      table8: [],
      table9: [],
      listStatker: [],
      listBalai: [],
      listGiat: [],
      listEvent: [],
      listRegister:[],
      selectSatker: null,
      isTotalKodeAkun:false,
      isKodeAkunS1:false,
      isKodeAkunS2:false,
      isKodeAkunS3:false,
      istableLoan:false,
      istableSBSN:false,
      istableProyek:false,
      istableIPDMIP:false,
      istableSIMURP:false,
      istableDOISP:false,
      istable8:false,
      istable9:false,
      istableOutput:false,
      isShow:false,
      setBalaiValue:null,
      setSatkerValue:null,
      setGiatValue:null,
      data_color: ["#00C5DC", "#F4516C", "#FFB822", "#8859E0", "#0C5484", "#66BB6A", "#00838F", "#e57373"],

    }
  },
  created() {
    this.$emit(`update:layout`, LayoutAdmin);
  },
  mounted() {  

    this.G_GetRef('giat').then(data => {
      this.listGiat = data;
    });

    this.G_GetRef('register').then(data => {
      this.listRegister = data;
        this.listRegister.unshift({
        'text': 'Semua Register',
        'value': 'all'
      })

    });
    // this.G_GetAny('sakti/event').then(res => {
    //   let resData = res.data.content

    //   let listData = [];
    //   let arrSub = {};
    //   let setDefault = null

    //   resData.forEach(v => {

    //     if (v.asDefault) {
    //       setDefault = v.saktiEventId
    //     }

    //     arrSub = {};

    //     arrSub['text'] = v.saktiEventNm;
    //     arrSub['value'] = v.saktiEventId;

    //     listData.push(arrSub);
    //   });

    //   this.listEvent = listData;

    //   this.listEvent.unshift({
    //     'text': 'Semua Event',
    //     'value': 'all'
    //   })
    //   this.$refs.event.setValue(setDefault)
      

    // })


    this.getSummary()


    this.G_GetRef('balai').then(data => {
      this.listBalai = data;

      if (this.getUser.userGroup == "balai") {
        // this.setBalaiValue = this.getUser.kdbalai
        this.$refs.balai.setValue(this.getUser.kdbalai)

      }else if(this.getUser.userGroup == "satker"){
       
        // this.setBalaiValue = this.getUser.kdbalai

        this.$refs.balai.setValue(this.getUser.kdbalai)

      }else{
        this.listBalai.unshift({
            'text': 'Semua Balai',
            'value': 'all'
          })
      }

    });

    this.G_GetRef('satker').then(data => {
      this.listStatker = data;
      if (this.getUser.userGroup == "satker") {

        // this.setBalaiValue = this.getUser.kdsatker
        this.$refs.satker.setValue(this.getUser.kdsatker)

      }else{
        this.listStatker.unshift({
            'text': 'Semua Satker',
            'value': 'all'
          })
      }
       
    });

    // this.getSummary()
  },
  // components: {
  //   myTable,myTableBalai
  // },
  computed: {
   

  },
  methods: {
    getSumRegister(type) {
      let s1 = 0
      let s2 = 0
      let s3 = 0
      let total = 0

      this.tableLoan.forEach(v => {
        s1 += v.sumAkun51
        s2 += v.sumAkun52
        s3 += v.sumAkun53
        total += v.total
      });

      switch (type) {
        case 's1':
          return this.G_numFormat(s1 / 1000)
          break;
        case 's2':
          return this.G_numFormat(s2 / 1000)
          break;
        case 's3':
          return this.G_numFormat(s3 / 1000)
          break;
        case 'total':
          return this.G_numFormat(total / 1000)
          break;
      }
    },
    getSumRegisterSBSN(type) {
      let s1 = 0
      let s2 = 0
      let s3 = 0
      let total = 0

      this.tableSBSN.forEach(v => {
        s1 += v.sumAkun51
        s2 += v.sumAkun52
        s3 += v.sumAkun53
        total += v.total
      });

      switch (type) {
        case 's1':
          return this.G_numFormat(s1 / 1000)
          break;
        case 's2':
          return this.G_numFormat(s2 / 1000)
          break;
        case 's3':
          return this.G_numFormat(s3 / 1000)
          break;
        case 'total':
          return this.G_numFormat(total / 1000)
          break;
      }
    },
    setSatker(id) {
      if (id != 'all' || id == null) {
        this.G_GetRef('satker', '?balaiId=' + id).then(data => {
          this.listStatker = data;
          this.listStatker.unshift({
            'text': 'Semua Satker',
            'value': 'all'
          })
        });
      } else {
        this.G_GetRef('satker').then(data => {
          this.listStatker = data;
          this.listStatker.unshift({
            'text': 'Semua Satker',
            'value': 'all'
          })
        });
      }


    },
    toggleSub(key) {
     
      this.isActive[key] = !this.isActive[key];

      if (this.isActive[key]) {
        $('#sub_' + key).hide();
      } else {
        $('#sub_' + key).show();

      }

      
    },
    getSummary(setfilter) { 

      this.isShow = true
      this.isTotalKodeAkun=true
      this.isKodeAkunS1=true
      this.isKodeAkunS2=true
      this.isKodeAkunS3=true
      this.istableLoan=true
      this.istableSBSN=true
      this.istableProyek=true
      this.istableOutput=true

      let filter = [];

      if (setfilter) {
        filter = setfilter
      }else{
        this.setBalaiValue =  this.getUser.kdbalai
        this.setBalaiValue =  this.getUser.kdsatker

        if (this.setBalaiValue) {
          if (this.setBalaiValue != 'all') {
            filter.push('kdbalai=' + this.setBalaiValue) 
          }
        }
  
        if (this.setBalaiValue) {
          if (this.setBalaiValue != 'all') {
            filter.push('kdsatker=' + this.setBalaiValue) 
          }
        }
  
        let giat = []
  
        if (this.setGiat) {
            giat.push(this.setGiat) 
            let giatL = giat.join(',')
            filter.push('kdgiat=' + giatL) 
        }
      }

      

      
  

      filter.push('saktiEventId=' + this.$route.query.eventId) 


      filter = '?'+filter.join('&')


      this.G_GetAny('adk/resume/summary' + filter).then(res => {
        this.summary = res.data
        this.isTotalKodeAkun = false
        this.isKodeAkunS1 = false
        this.isKodeAkunS2 = false
        this.isKodeAkunS3 = false
      })
      this.G_GetAny('adk/resume/summary/sbsn' + filter).then(res => {
        this.tableSBSN = res.data.content
        this.istableSBSN = false
        
      })
      this.G_GetAny('adk/resume/summary/proyek' + filter).then(res => {
        this.tableProyek = res.data.content
        this.istableProyek = false

      })
      this.G_GetAny('adk/resume/summary/loan' + filter).then(res => {
        this.tableLoan = res.data.content
        this.istableLoan = false

      })

      this.G_GetAny('adk/resume/summary/output' + filter).then(res => {
        this.tableOutput = res.data.content
        this.istableOutput = false

      })

    },
    cariData() {

      let filter = [];

      if (this.$refs.satker.getValue()) {
        if (this.$refs.satker.getValue() != 'all') {
          filter.push('kdsatker=' + this.$refs.satker.getValue()) 
        }
      }

      if(this.$refs.balai.getValue()){
        if (this.$refs.balai.getValue() != 'all') {
          filter.push('kdbalai=' + this.$refs.balai.getValue()) 
        }
        
      }

      if(this.$refs.register.getValue()){
        if (this.$refs.register.getValue() != 'all') {
          filter.push('kdregister=' + this.$refs.register.getValue()) 
        }
        
      }
    

     
      let giat = []

      if (this.$refs.giat.getValue()) {
        giat.push(this.$refs.giat.getValue()) 
        let giatL = giat.join(',')
        filter.push('kdgiat=' + giatL) 
    }

      this.getSummary(filter);
    },
    detailPaket(kodeSatker) {
      urutan = 0

      this.G_GetAny('kontraktual/per-paket/nested?kdsatker=' + kodeSatker + '&tahun=2021').then(res => {
        this.tableDetailPaket = res.data.content[0].childs[0]

        this.$refs.modal.open("DETAIL PAKET " + this.tableDetailPaket.nmsatker, 1200)
      })

    },

    setNoUrutIPDMIP(idx) {
      urutantableIPDMIP++
      return urutantableIPDMIP
    },
    setNoUrutDOISP(idx) {
      urutantableDOISP++
      return urutantableDOISP
    },
    setNoUrutSIMURP() {
      urutantableSIMURP++
      return urutantableSIMURP
    },
    selectAll(type){
      if(type == 'kolom'){
        this.$refs.colomTable.selectAll()
      }
      else if(type == 'giat'){
        this.$refs.giat.selectAll()

      }
    },
    removeAll(type){
      if(type == 'kolom'){
        this.$refs.colomTable.removeAll()
      }
      else if(type == 'giat'){
        this.$refs.giat.removeAll()

      }
    }
  },
  computed: {
  }
}